<template>
  <div class="instruments pt-5" v-if="!market.disable_charts">
    <div class="preloader" v-if="!loaded">
        <div class="preloader-overlay"></div>
        <base-logo-wave-anim/>
    </div>

    <div class="sort-by d-none">
      {{ $t('components.instruments.sort_by') }}
      <span>Date</span>
      <div class="d-inline-block dropdown-icon-wrapper">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>

    <div class="instrument"
         v-for="instrument in filtered_instruments"
         v-bind:key="instrument.id"
    >

      <b-row class="no-gutters" :id="instrument.slug">
        <b-col xl="5" class="left-col">
          <h3 v-html="instrument.name"></h3>
          <i class="fa-star"
             :class="{ fas: is_favourite(instrument.id), far: !is_favourite(instrument.id) }"
             @click.stop="toggleFavouriteItem(instrument)"
          ></i>

          <img alt="Bias" v-b-tooltip="{trigger: 'hover'}" :title="instrument.bias.description" class="img-fluid" v-bind:src="biasIconUrl(instrument.bias.key, { big: true })">

          <h5>
            <time-ago :refresh="60" :long="true" :datetime="instrument.updated_at" />
          </h5>

          <p v-html="instrument.content" v-if="check_for_subscription()"></p>

          <div class="blur" v-if="!check_for_subscription()">
            <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.</p>
            <p><b>2021-01-27 21:51</b></p>
            <p>EURUSD is sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem</p>
            <p>Vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
          </div>

          <router-link
            :to="{ name: `${market.slug}.instruments.intraday-updates`, params: { intraday_updates_instrument_slug: instrument.slug } }"
            v-if="instrument.intraday_updates.length > 0 && check_for_subscription()"
          >
            <a>{{ $t('components.instruments.view_intraday_updates') }}</a>
          </router-link>

        </b-col>

        <b-col class="right-col" :class="{'charts-popup-wrapper': is_popup_open[instrument.id]}">
          <div class="overlay" @click="toggle_charts_popup(instrument.id)"></div>
          <div :class="{'popup': is_popup_open[instrument.id]}">
            <div class="close-popup">
              <span @click="toggle_charts_popup(instrument.id)">
                <i class="fas fa-times"></i>
              </span>
            </div>

            <base-tabs
              :tabs=instrument.charts
              tooltip_trigger='hover'
              image_tooltip_trigger='hover'
              :bias_icon_size=false
              :market=market.slug
              :demo="!check_for_subscription()"
              @is-popup-open="toggle_charts_popup(instrument.id)"
            >
            </base-tabs>
          </div>

          <trading-notes
            :instrument_id="instrument.id"
            :market=market.slug
            :demo="!check_for_subscription()"
            v-if="instrument_trading_notes(instrument.id).length > 0"
          />

        </b-col>
      </b-row>
    </div>

    <router-view></router-view>

  </div>
</template>

<script>
import BaseTabs from '../BaseTabs.vue'
import TradingNotes from '../TradingNotes.vue'
// import BasePopup from '../../components/BasePopup.vue'
import { mapState, mapGetters } from 'vuex'
import BaseLogoWaveAnim from '../BaseLogoWaveAnim.vue'

export default {
  data () {
    return {
      market: Object,
      selected_instrument_slug: 'all',
      is_popup_open: [],
      loaded: false
    }
  },
  components: {
    // BasePopup,
    BaseTabs,
    TradingNotes,
    BaseLogoWaveAnim
  },
  mounted () {
    const that = this
    this.$store.dispatch('market/loadMarkets').then(() => {
      that.market = that.markets.find(market => market.slug === that.$route.matched[0].meta.market)
    })

    if (this.$route.params.instrument_slug) {
      this.selected_instrument_slug = this.$route.params.instrument_slug
    }

    this.$store.dispatch('market/instruments/loadInstruments', { market: that.$route.matched[0].meta.market }).then(() => {
      this.loaded = true
      setTimeout(() => {
        this.scrool_to_anchtor()
      }, 2000)
    })
    this.$store.dispatch('market/instruments/loadFavourites')
  },
  computed: {
    ...mapState({
      instruments: state => state.market.instruments.instruments,
      favourites: state => state.market.instruments.favourites,
      current_user: state => state.profile.account.current_user,
      markets: state => state.market.markets
    }),
    ...mapGetters({
      is_favourite: 'market/instruments/is_favourite',
      instrument_trading_notes: 'trading_notes/instrument_trading_notes'
    }),
    filtered_instruments () {
      return this.instruments.filter(instrument => {
        if (this.$route.params.instrument_slug === 'favourites') {
          return this.is_favourite(instrument.id)
        }

        return true
      })
    }
  },
  methods: {
    is_intraday_closed (market) {
      return this.$route.name === `${market}.instruments.intraday-updates`
    },
    toggle_charts_popup (instrument_id) {
      this.$set(this.is_popup_open, instrument_id, !this.is_popup_open[instrument_id])
    },
    toggleFavouriteItem (instrument) {
      this.$store.dispatch('market/instruments/updateFavourites', { favourite_id: instrument.id })
    },
    scrool_to_anchtor () {
      if (this.$route.params.instrument_slug) {
        this.$scrollTo(`#${this.$route.params.instrument_slug}`, 500, { container: '.content', offset: -60, y: true })
      }
    },
    check_for_subscription () {
      if (this.current_user.open_door && this.current_user.open_door_accessed) {
        return true
      }

      const market_subscription = this.current_user.subscriptions.filter(
        s => s.markets.some(
          m => m.slug === this.market.slug
        )
      ).length

      return !(this.current_user.subscriptions.length === 0 || market_subscription === 0)
    }
  }
}
</script>

<style scoped lang="scss">

.blur {
  filter: blur(3px);
}

.preloader {
  width: calc(100% + 150px);
  height: 100%;
  background-color: var(--ice-blue-charcoal-grey-two);
  left: -150px;

  .preloader-overlay {
    background-color: var(--ice-blue-charcoal-grey-two);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 140px;
    left: 0;
    z-index: 1;
  }

  img {
    transform: translate(calc(-50% - 75px), -50%);
    z-index: 2;
  }

  @media (max-width: 575.98px) {
    left: 0;
    img {
      transform: translate(-50%, -50%);
    }
  }
}

.instruments {
  padding: 0 75px 0 50px;
  background-color: var(--white-black);

  .close-single {
    display: none;
  }

  .sort-by {
    color: var(--cool-grey);
    text-align: right;
    font-size: 11px;
    line-height: 58px;

    span {
      font-weight: 600;
      margin-right: 5px;
    }

    .dropdown-icon-wrapper {
      position: relative;
      top: 1px;
    }
  }

  @media (max-width: 767.98px) {
    padding: 0 25px 0 30px;
  }
}

.instrument {
  margin-bottom: 100px;

  .dropdown-icon-wrapper {
    display: none;
  }

  .close-popup {
    display: none;
  }

  @media (min-width: 576px) {
    .charts-popup-wrapper {
      background-color: var(--white-70-black-70);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 250;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      @include basic-transition;

      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      &.right-col {
        margin-top: 0;
      }

      .popup {
        position: relative;
        max-width: 900px;
        width: 100%;
        padding: 45px 50px;
        margin: 15px auto;
        background-color: var(--pale-grey-two-charcoal-grey-two);
        border: solid 1px var(--pale-grey-battleship-grey-three);
        overflow-y: visible;
        height: auto;

        .close-popup {
          position: absolute;
          top: 30px;
          right: 30px;
          cursor: pointer;
          display: block;

          i {
            color: var(--cloudy-blue);
            font-size: 20px;
            margin-top: 0;
          }
        }
      }

      ::v-deep .nav-tabs {
        .nav-item {
          max-width: 150px;
        }

        .nav-link {
          background-color: var(--white-black);

          h4 {
            line-height: 35px;
            display: inline-block;
            padding: 0;
          }

          img {
            display: inline-block;
            margin-left: 15px;
            position: initial;
            top: 0;
            transform: none;
          }

          small,
          .v-time-ago__text {
            float: right;
            line-height: 35px;
            padding-bottom: 0;
            font-size: 10px;
          }

          &.active {
            background-color: var(--muted-blue);

            small,
            .v-time-ago__text {
              color: var(--white);
              opacity: 0.6;
            }
          }
        }
      }

      ::v-deep .tab-content img {
        margin-bottom: 0;
      }

      ::v-deep .v-time-ago__text {
        font-size: 12px;
        line-height: 2;
        margin-bottom: 0;
      }

      @media (max-width: 991.98px) {
        .popup {
          padding: 60px 20px;
        }
      }

      @media (orientation: landscape) and (max-height: 800px) {
        &.right-col {
          padding: 15px;
        }

        .popup {
          margin: 0 auto;
        }
      }

      .trading-notes {
        display: none;
      }
    }
  }

  @media (min-width: 1500px) {
    .charts-popup-wrapper .popup {
      margin: 50px auto;
    }
  }

  .right-col {
    ::v-deep .tab-content {
      img {
        cursor: pointer;
      }
    }
  }

  .left-col {
    h3 {
      font-size: 20px;
      color: var(--charcoal-grey-white);
      display: inline-block;

      ::v-deep span {
        font-weight: 300;
      }
    }

    i {
      @include ease-transition(1s);
      cursor: pointer;
      color: var(--cloudy-blue-battleship-grey-three);
      margin-left: 10px;
      vertical-align: text-top;
      font-size: 13px;
      margin-top: 2px;
    }

    img {
      float: right;
      margin-top: 2px;
    }

    h5,
    ::v-deep .v-time-ago__text {
      font-size: 12px;
      line-height: 2;
      margin-bottom: 20px;
      color: var(--cool-grey);
    }

    p {
      font-size: 15px;
      font-family: 'Athelas', arial, sans-serif;
      letter-spacing: .09px;
      color: var(--charcoal-grey-white);
      line-height: 1.73;
    }

    a {
      font-size: 14px;
      color: var(--muted-blue-dark-sky-blue);
      font-family: Heebo;
      cursor: pointer;
    }

    @media (min-width: 1200px) {
      margin-right: 45px;
    }
  }

  @media (max-width: 1199.98px) {
    .no-gutters {
      margin-right: -15px;
      margin-left: -15px;
    }

    .left-col {
      padding: 0 15px;
    }

    .right-col {
      margin-top: 40px;
      padding: 0 15px;
    }
  }
}

.dark-theme .left-col img {
  filter: brightness(0) invert(1);
}
</style>
